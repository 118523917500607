import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Divider,
  Typography,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoImage from '../assets/logo.svg';
import darkLogoImage from '../assets/logoDark.svg';
import '../styles/NavBar.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn, logout } from '../store/slices/authSlice';
import { getAuth } from 'firebase/auth';
import useWindowSizeListener from '../hooks/useWindowSizeListener';
import { LayoutContext } from '../context/LayoutContext';
import { theme } from '../layout/Theme';
import phone from '../assets/icons/phone.png';
import { useNavigate } from "react-router-dom";

function Navbar() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { isChatStarted, setIsChatStarted, themeMode } = useContext(LayoutContext);
  const { windowSize } = useWindowSizeListener();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/doro-test-start' && isChatStarted) {
      setShowCloseIcon(true);
    } else {
      setShowCloseIcon(false);
    }
  }, [location.pathname, isChatStarted]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth
      .signOut()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  };

  const handleEndChat = () => {
    setIsChatStarted(false);
    navigate('/profile');
  };

  return (
    <>
      <AppBar position="fixed" elevation={1} className={`navbar ${location.pathname === '/doro-test-start' ? 'chat-page' : ''}`} >
        <Toolbar
          style={{
            justifyContent: 'space-between',
            marginLeft: '0.5%',
            marginRight: '0.5%',
          }}
        >
          <Link to="/" style={{ flexGrow: 1 }} onClick={() => setIsChatStarted(false)}>
            <img
              src={(themeMode === 'dark' || windowSize.deviceType === 'large') ? logoImage : darkLogoImage}
              alt='Doro'
              style={{ height: '50px', width: 'auto', marginTop: '5px' }}
            />
          </Link>
          {showCloseIcon ? (
            <IconButton
              edge="start"
              aria-label="close"
              disableRipple={true}
              sx={{
                display: { xs: 'block', lg: 'none' },
                fontSize: { xs: '2rem', sm: '2.5rem' },
                marginRight: '-8px',
              }}
              onClick={handleEndChat}
            >
              <CloseIcon
                className="closeIcon"
                sx={{ fontSize: 'inherit', color: themeMode === 'dark' ? 'white' : theme.palette.scrollbarC }}
              />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              aria-label="menu"
              disableRipple={true}
              sx={{
                display: { xs: 'block', lg: 'none' },
                fontSize: { xs: '2rem', sm: '2.5rem' },
              }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon
                className="hamburgerIcon"
                sx={{ fontSize: 'inherit', color: themeMode === 'dark' ? 'white' : theme.palette.scrollbarC }}
              />
            </IconButton>
          )}
          <Drawer
            anchor='right'
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            ModalProps={{
              BackdropProps: {
                style: {
                  backgroundColor: 'rgba(0, 0, 0, 0.15)',
                },
              },
            }}
            PaperProps={{
              style: {
                boxShadow: '2px 0px 45px rgba(0, 0, 0, 0.26)',
              },
            }}
          >
            <div
              role="presentation"
              className="drawerBox"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <IconButton
                className='chat-page'
                disableRipple={true}
                aria-label="close"
                onClick={toggleDrawer(false)}
                sx={{
                  position: 'absolute',
                  right: '7.5%',
                  top: 8,
                  fontSize: { xs: '2rem', sm: '2.5rem' },
                  color: 'white',
                  marginRight: '-8px',
                }}
              >
                <CloseIcon className='closeIcon' sx={{ fontSize: 'inherit' }} />
              </IconButton>
              <Link className="option" to="/">Home</Link>
              <Divider className="divider" />
              <Link className="option" to={isLoggedIn ? '/doro-test-start' : '/get-started'}>DORO</Link>
              <Divider className="divider" />
              <Link className="option" to="/resources">Questionnaires</Link>
              <Divider className="divider" />
              {isLoggedIn ? (
                <Link className="option" to="/profile">Profile</Link>
              ) : (
                <Link className="option" to="/get-started">Get Started</Link>
              )}
              {isLoggedIn && <Divider className="divider" />}
              {isLoggedIn && (
                <Link className="option" to="/" onClick={handleLogout}>Logout</Link>
              )}
              <Divider className="divider" />
              <Typography
                component={'span'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 5,
                  cursor: 'pointer',
                  color: 'white',
                  marginTop: '10px',
                }}
                onClick={() => window.open('https://findahelpline.com/', '_blank', 'noopener,noreferrer')}
              >
                <img
                  src={phone}
                  alt="Help Line"
                  style={{ height: '25px', width: 'auto', marginRight: '8px' }}
                />
                <div>
                  <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Help is available</span>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '14px',
                      marginTop: '-5px',
                    }}
                  >
                    Speak with someone today
                  </span>
                </div>
              </Typography>
            </div>
          </Drawer>
          {location.pathname === '/doro-test-start' && (
          <Typography
            component={'span'}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 5,
              cursor: 'pointer',
            }}
            sx={{
              '@media (max-width: 1080px)': {
                display: 'none !important;',
              },
            }}
            onClick={() => window.open('https://findahelpline.com/', '_blank', 'noopener,noreferrer')}
          >
            <img
              src={phone}
              alt='Help Line'
              style={{ height: '25px', width: 'auto', marginRight: '8px' }}
            />
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Help is available
              </span>
              <span
                style={{
                  display: 'block',
                  fontSize: '14px',
                  marginTop: '-5px',
                }}
              >
                Speak with someone today
              </span>
            </div>
          </Typography>
        )}
        <Button
          color='inherit'
          className='buttonStyle'
          sx={{
            marginRight: '3%',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#CBD0DB',
            },
          }}
          component={Link}
          to='/'
        >
          Home
        </Button>
        <Button
          color='inherit'
          className='buttonStyle'
          sx={{
            marginRight: '3%',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#CBD0DB',
            },
          }}
          component={Link}
          to={isLoggedIn ? '/doro-test-start' : '/get-started'}
        >
          DORO
        </Button>
        <Button
          color='inherit'
          className='buttonStyle'
          sx={{
            marginRight: '3%',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#CBD0DB',
            },
          }}
          component={Link}
          to='/resources'
        >
          Questionnaires
        </Button>
        {isLoggedIn ? (
          <Button
            color='inherit'
            className='buttonStyle'
            variant='outlined'
            sx={{
              borderRadius: '25px',
              marginRight: '3%',
              '&:hover, &:active': {
                backgroundColor: 'transparent !important',
                color: '#CBD0DB !important',
              },
            }}
            component={Link}
            to='/profile'
          >
            Profile
          </Button>
        ) : (
          <Button
            color='inherit'
            className='buttonStyle'
            variant='outlined'
            sx={{
              borderRadius: '25px',
              '&:hover, &:active': {
                backgroundColor: 'transparent !important',
                color: '#CBD0DB !important',
              },
            }}
            component={Link}
            to='/get-started'
          >
            Get Started
          </Button>
        )}
        {isLoggedIn && (
          <Button
            color='inherit'
            className='buttonStyle'
            variant='outlined'
            sx={{
              borderRadius: '25px',
              '&:hover, &:active': {
                backgroundColor: 'transparent !important',
                color: '#CBD0DB !important',
              },
            }}
            component={Link}
            to='/'
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;
